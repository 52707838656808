var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { match } from 'ts-pattern';
import { createThemedView } from '../utils/createThemedStylesHook';
import randomHexColor from '../utils/randomHexColor';
const Column = createThemedView((_a) => {
    var { center, spaceBetween, spaceAround, spaceEvenly, centerY, centerX, fill, colorize, marginX, marginY, paddingY, paddingX, style, backgroundColor } = _a, props = __rest(_a, ["center", "spaceBetween", "spaceAround", "spaceEvenly", "centerY", "centerX", "fill", "colorize", "marginX", "marginY", "paddingY", "paddingX", "style", "backgroundColor"]);
    return ([
        Object.assign({ alignItems: center || centerX ? 'center' : undefined, backgroundColor: backgroundColor || (colorize ? randomHexColor() : undefined), flex: fill ? 1 : undefined, justifyContent: match({
                spaceBetween, spaceAround, spaceEvenly, center, centerY,
            })
                .with({ spaceBetween: true }, () => 'space-between')
                .with({ spaceAround: true }, () => 'space-around')
                .with({ spaceEvenly: true }, () => 'space-evenly')
                .with({ center: true }, () => 'center')
                .with({ centerY: true }, () => 'center')
                .otherwise(() => undefined), marginHorizontal: marginX, marginVertical: marginY, paddingHorizontal: paddingX, paddingVertical: paddingY }, props),
        style,
    ]);
});
export default Column;

import { useEffect, useRef, useState } from 'react';
const isPrefixed = !document.hasOwnProperty('hidden') && document.hasOwnProperty('webkitHidden');
const VISIBILITY_CHANGE_EVENT = isPrefixed ? 'webkitvisibilitychange' : 'visibilitychange';
const VISIBILITY_STATE_PROPERTY = isPrefixed ? 'webkitVisibilityState' : 'visibilityState';
const isAvailable = document[VISIBILITY_STATE_PROPERTY];
const getCurrentState = (isFocused) => {
    if (!isAvailable) {
        return isFocused ? 'active' : 'inactive';
    }
    switch (document[VISIBILITY_STATE_PROPERTY]) {
        case 'hidden':
        case 'prerender':
        case 'unloaded':
            return 'background';
        default:
            return isFocused ? 'active' : 'inactive';
    }
};
const useAppState = () => {
    const [appState, setAppState] = useState(getCurrentState(document.hasFocus()));
    const isFocused = useRef(false);
    useEffect(() => {
        const onUpdate = () => setAppState(getCurrentState(isFocused.current));
        const onBlur = () => { isFocused.current = false; onUpdate(); };
        const onFocus = () => { isFocused.current = true; onUpdate(); };
        window.addEventListener('blur', onBlur);
        window.addEventListener('focus', onFocus);
        window.addEventListener(VISIBILITY_CHANGE_EVENT, onUpdate, false);
        return () => {
            window.removeEventListener('blur', onBlur);
            window.removeEventListener('focus', onFocus);
            window.removeEventListener(VISIBILITY_CHANGE_EVENT, onUpdate);
        };
    }, []);
    return appState;
};
export default useAppState;

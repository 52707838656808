import React, { useCallback, } from 'react';
import { StyleSheet, Text, TouchableOpacity, View, } from 'react-native';
import Animated, { FadeInUp, FadeOutDown, SequencedTransition, } from 'react-native-reanimated';
const DEFAULT_BACKGROUND_COLOR = '#323232', DEFAULT_BUTTON_TEXT_COLOR = '#B28FF0', DEFAULT_TEXT_COLOR = '#CDCDCD';
const styles = StyleSheet.create({
    buttonText: {
        color: DEFAULT_BUTTON_TEXT_COLOR,
        fontWeight: '500',
        padding: 8,
        paddingLeft: 16,
        textAlign: 'right',
        textTransform: 'uppercase',
    },
    snackbar: {
        backgroundColor: DEFAULT_BACKGROUND_COLOR,
        paddingHorizontal: 16,
        paddingVertical: 10,
        borderRadius: 5,
        margin: 10,
        minHeight: 48,
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 3,
        justifyContent: 'space-between',
        flexDirection: 'row',
        alignItems: 'center',
        flexWrap: 'wrap',
    },
    snackbarText: {
        color: DEFAULT_TEXT_COLOR,
    },
    snackbarButtonWrapper: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
        flexGrow: 1,
    },
});
const DEFAULT_ANIMATION_DURATION = 250;
export const DefaultSnackbarComponent = React.memo(({ snackbarConfig, doDismiss, textStyle, backgroundColor, buttonColor, buttonTextStyle, id, style, entering, layout, exiting, }) => {
    var _a;
    const renderButton = useCallback((a, index) => (React.createElement(TouchableOpacity, { key: a.key || a.label, onPress: () => {
            var _a;
            doDismiss(id);
            (_a = a.onPress) === null || _a === void 0 ? void 0 : _a.call(a, a);
        } },
        React.createElement(Text, { style: [
                styles.buttonText,
                buttonTextStyle,
                buttonColor ? { color: buttonColor } : null,
                index === 0 ? null : { marginLeft: 16 },
            ] }, a.label))), [id]);
    return React.createElement(Animated.View, { entering: entering !== null && entering !== void 0 ? entering : FadeInUp.duration(DEFAULT_ANIMATION_DURATION), layout: layout !== null && layout !== void 0 ? layout : SequencedTransition.duration(DEFAULT_ANIMATION_DURATION * 2), exiting: exiting !== null && exiting !== void 0 ? exiting : FadeOutDown.duration(DEFAULT_ANIMATION_DURATION) },
        React.createElement(View, { style: [styles.snackbar, style, backgroundColor ? { backgroundColor } : null] },
            React.createElement(Text, { style: [styles.snackbarText, textStyle] }, snackbarConfig.title),
            React.createElement(View, { style: styles.snackbarButtonWrapper }, (_a = snackbarConfig.actions) === null || _a === void 0 ? void 0 : _a.map(renderButton))));
});
export default DefaultSnackbarComponent;

var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { createThemedText } from '../utils/createThemedStylesHook';
import randomHexColor from '../utils/randomHexColor';
const Text = createThemedText((_a) => {
    var { center, centerY, centerX, fill, colorize, marginX, marginY, paddingY, paddingX, theme, fontWeight, size, insets, style } = _a, props = __rest(_a, ["center", "centerY", "centerX", "fill", "colorize", "marginX", "marginY", "paddingY", "paddingX", "theme", "fontWeight", "size", "insets", "style"]);
    return ([
        Object.assign({ backgroundColor: colorize ? randomHexColor() : undefined, color: theme.colors.text, flex: fill ? 1 : undefined, fontWeight, marginHorizontal: marginX, marginVertical: marginY, paddingHorizontal: paddingX, paddingVertical: paddingY, textAlign: center || centerX ? 'center' : undefined, textAlignVertical: centerY || center ? 'center' : undefined }, props),
        style,
    ]);
});
export default Text;

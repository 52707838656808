import React, { useCallback, useMemo, useRef, useState, } from 'react';
import getRandomID from '../utils/getRandomID';
const SnackbarContextDefault = {
    snackbarsToShow: [],
    addSnackbar: (snackbarConfig) => {
        console.warn('[@kingstinct/react] SnackbarContext not initialized, please wrap the app in SnackbarProvider');
    },
    removeSnackbar: (id) => { },
    snackbarWasPresented: (id) => { },
};
export const SnackbarContext = React.createContext(SnackbarContextDefault);
let hasWarned = false;
export const SnackbarProvider = ({ children, defaultTimeoutMs = 5000, snackbarsToShowAtSameTime = 1, }) => {
    const [snackbars, setSnackbars] = useState([]);
    const timeouts = useRef(new Map());
    const addSnackbar = useCallback(function addSnackbar(snackbarConfig) {
        setSnackbars((s) => [
            ...s, {
                snackbarConfig: Object.assign(Object.assign({}, snackbarConfig), { type: snackbarConfig.type, data: snackbarConfig.data }),
                id: snackbarConfig.id || getRandomID(),
            },
        ]);
        if (!hasWarned) {
            setImmediate(() => {
                if (timeouts.current.size === 0) {
                    console.warn('[@kingstinct/react] Snackbar added but not shown, make sure SnackbarView is present (or that you\'re calling snackbarWasPresented if rolling your own).');
                    hasWarned = true;
                }
            });
        }
    }, []);
    const removeSnackbar = useCallback((id) => {
        setSnackbars((snacks) => snacks.filter((s) => s.id !== id));
    }, []);
    const snackbarWasPresented = useCallback((id) => {
        const snackbar = snackbars.find((s) => s.id === id);
        if (!timeouts.current.has(id)) {
            timeouts.current.set(id, setTimeout(() => {
                removeSnackbar(id);
                timeouts.current.delete(id);
            }, (snackbar === null || snackbar === void 0 ? void 0 : snackbar.snackbarConfig.timeout) || defaultTimeoutMs));
        }
    }, [defaultTimeoutMs, removeSnackbar, snackbars]);
    return (React.createElement(SnackbarContext.Provider, { value: useMemo(() => ({
            addSnackbar,
            snackbarWasPresented,
            removeSnackbar,
            snackbarsToShow: snackbars.slice(0, snackbarsToShowAtSameTime),
        }), [
            addSnackbar, removeSnackbar, snackbarWasPresented, snackbars, snackbarsToShowAtSameTime,
        ]) }, children));
};

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useCallback, useState } from 'react';
import { RefreshControl, } from 'react-native';
export default React.forwardRef(function RefetchControl(_a, ref) {
    var { onRefetch, onStatusChange } = _a, props = __rest(_a, ["onRefetch", "onStatusChange"]);
    const [isRefetching, setIsRefetching] = useState(false);
    const onRefetchWithPull = useCallback(() => __awaiter(this, void 0, void 0, function* () {
        setIsRefetching(true);
        onStatusChange === null || onStatusChange === void 0 ? void 0 : onStatusChange(true);
        try {
            if (onRefetch) {
                yield onRefetch();
            }
        }
        finally {
            setIsRefetching(false);
            onStatusChange === null || onStatusChange === void 0 ? void 0 : onStatusChange(false);
        }
    }), [onRefetch, onStatusChange]);
    return (React.createElement(RefreshControl, Object.assign({ ref: ref }, props, { refreshing: isRefetching, onRefresh: onRefetchWithPull })));
});

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import AsyncStorage from '@react-native-async-storage/async-storage';
import React, { useEffect, useMemo, useState, createContext, } from 'react';
export const AuthContext = createContext({
    clearToken: () => { },
    hasToken: false,
    setToken: () => { },
    token: null,
});
const AUTH_TOKEN_KEY = 'AUTH_TOKEN';
const AuthProvider = ({ children }) => {
    const [token, setToken] = useState(null);
    useEffect(() => {
        const init = () => __awaiter(void 0, void 0, void 0, function* () {
            const t = yield AsyncStorage.getItem(AUTH_TOKEN_KEY);
            setToken(t);
        });
        void init();
    }, []);
    const value = useMemo(() => ({
        clearToken: () => {
            setToken(null);
            void AsyncStorage.removeItem(AUTH_TOKEN_KEY);
        },
        hasToken: !!token,
        setToken: (t) => {
            setToken(t);
            void AsyncStorage.setItem(AUTH_TOKEN_KEY, t);
        },
        token,
    }), [token]);
    return (React.createElement(AuthContext.Provider, { value: value }, children));
};
export default AuthProvider;

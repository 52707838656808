import React, { createContext, useMemo } from 'react';
const DEFAULT_VALUE = {
    'Try again': 'Try again',
    'You are offline': 'You are offline',
    'Network request failed': 'Network request failed',
    'Something went wrong, please try again': 'Something went wrong, please try again',
};
const StringsContext = createContext(DEFAULT_VALUE);
export const StringsProvider = ({ children, strings }) => React.createElement(StringsContext.Provider, { value: useMemo(() => (Object.assign(Object.assign({}, DEFAULT_VALUE), strings)), []) }, children);
export default StringsContext;

import React, { useCallback } from 'react';
import { Pressable } from 'react-native';
export function PressableOpacity({ style, disabled = false, disabledOpacity = 0.3, activeOpacity = 0.2, ...passThroughProps }) {
    const getOpacity = useCallback((pressed) => {
        if (disabled) {
            return disabledOpacity;
        }
        else {
            if (pressed)
                return activeOpacity;
            else
                return 1;
        }
    }, [activeOpacity, disabled, disabledOpacity]);
    const _style = useCallback(({ pressed }) => [style, { opacity: getOpacity(pressed) }], [getOpacity, style]);
    return <Pressable style={_style} disabled={disabled} {...passThroughProps}/>;
}
